.Form {
  border-radius: 50px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow: 5px 5px 10px #c7c7c7, -5px -5px 10px #ffffff;
}
.input {
  border: none;
  padding: 1rem;
  border-radius: 5rem;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow: 5px 5px 10px #c7c7c7, -5px -5px 10px #ffffff;
  transition: 0.3s;
}

.input:focus {
  outline-color: #e8e8e8;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow: 5px 5px 10px #c7c7c7, -5px -5px 10px #ffffff;
  transition: 0.3s;
}
/* color table */

.inputColor {
  border: none;
  padding: 1rem;
  border-radius: 1rem 1rem 1rem 1rem;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow: 5px 5px 10px #c7c7c7, -5px -5px 10px #ffffff;
  transition: 0.3s;
}

.inputColor:focus {
  outline-color: #e8e8e8;
  background: #e8e8e8;
  box-shadow: inset 20px 20px 60px #c5c5c5, inset -20px -20px 60px #ffffff;
  transition: 0.3s;
}

.MainColor {
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow: 5px 5px 10px #edecec, -5px -5px 10px #ffffff;
}
.inputTab {
  border-radius: 50px;
  background: linear-gradient(145deg, #ffffff, #e1e1e1);
  box-shadow: 5px 5px 10px #d7d7d7, -5px -5px 10px #ffffff;
}
.inputTab b {
  border-radius: 1px;
}
.colorSelect {
  border: none;
}

.MainFormBox {
  border-radius: 1rem;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow: 5px 5px 10px #c7c7c7, -5px -5px 10px #ffffff;
}
.submitBtn {
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow: 5px 5px 10px #c7c7c7, -5px -5px 10px #ffffff;
}
