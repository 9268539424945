/* LinkBtn.css */

.toggle {
  display: inline-block;
  position: relative;
  height: 50px;
  width: 50px;
  cursor: pointer;
}

.toggle .button {
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  box-shadow: 0 7.5px 12.5px -2px rgba(0, 0, 0, 0.4),
    inset 0 -1.5px 2px -0.5px rgba(0, 0, 0, 0.2),
    0 -5px 7.5px -0.5px rgba(255, 255, 255, 0.6),
    inset 0 1.5px 2px -0.5px rgba(255, 255, 255, 0.2),
    inset 0 0 2.5px 0.5px rgba(255, 255, 255, 0.8),
    inset 0 10px 15px 0 rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  position: absolute;
  background: #eaeaea;
  margin-left: -17.2px;
  margin-top: -17.2px;
  display: block;
  height: 34.4px;
  width: 34.4px;
  left: 50%;
  top: 50%;
}

.toggle.active .button {
  filter: blur(0.5px);
  box-shadow: 0 5px 12.5px -2px rgba(0, 0, 0, 0.4),
    inset 0 -4px 12.5px -0.5px rgba(255, 255, 255, 0.9),
    0 -5px 7.5px -0.5px rgba(255, 255, 255, 0.6),
    inset 0 4px 10px 0 rgba(0, 0, 0, 0.2),
    inset 0 0 2.5px 0.5px rgba(255, 255, 255, 0.6);
}

.toggle .label {
  transition: color 300ms ease-out;
  line-height: 50px;
  text-align: center;
  position: absolute;
  font-weight: 700;
  font-size: 14px;
  display: block;
  opacity: 0.9;
  height: 100%;
  width: 100%;
  color: rgba(0, 0, 0, 0.9);
}

.toggle input {
  opacity: 0;
  position: absolute;
  cursor: pointer;
  z-index: 1;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.toggle input:active ~ .button,
.toggle.active .button {
  filter: blur(0.5px);
  box-shadow: 0 6px 12.5px -2px rgba(0, 0, 0, 0.4),
    inset 0 -4px 15px 0.5px rgba(255, 255, 255, 0.9),
    0 -5px 7.5px -0.5px rgba(255, 255, 255, 0.6),
    inset 0 4px 12.5px 0 rgba(0, 0, 0, 0.4),
    inset 0 0 5px 0.5px rgba(255, 255, 255, 0.6);
}

.toggle .label:active {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
}

.toggle.active .label {
  color: rgba(0, 0, 0, 0.8);
}

.toggle .label svg {
  /* Center the SVG in the middle of the button */
  display: block;
  margin: auto;
  /* Set the icon color to black */
  width: 50%; /* Set the desired width for the SVG */
  height: 50%; /* Set the desired height for the SVG */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
